.gallery {
	overflow: hidden;
	.slick-list {
		overflow: visible;
	}
	&-container {
		@extend %container;
		padding: 0 2.625em;
		position: relative;
		display: block;
		@media #{$mq-mediumScreen} {
			padding: 0 20px;
		}
	}
	&-slider {
		overflow: visible;
		margin: 0 -0.75em;
	}
	&-slide {
		padding: 0 0.25em;
		@media #{$mq-mediumScreen} {
			padding: 0 0.75em;
		}
		&-container {
			overflow: hidden;
		}
		img {
			transform-origin: center;
			transition: transform 0.3s;
		}
	}
	.slick-slide-prev, .slick-slide-next {
		cursor: pointer;
		&:hover {
			img {
				transform: scale(1.1, 1.1);
			}
		}
	}
}
