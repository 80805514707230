.modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 980;
	justify-content: center;
	align-items: center;
	background: rgba(#000, 0.75);

	&.visible {
		display: flex;
	}

	&-wrapper {
		max-width: 80%;
		background: #ffffff;
		box-shadow: 0.5em 0.5em 1em rgba(#000, 0.2);
	}

	&-content {
		max-height: 70vh;
		overflow-x: hidden;
		overflow-y: auto;
	}

	&-content {
		padding: 1em;
	}

	&-actions {
		.button {
			margin: 0;
			display: block;
			width: 100%;
			max-width: none;
		}
		@media #{$mq-largeScreen} {
			padding: 1em;
			.button {
				display: inline-block;
				width: auto;
			}
		}
	}
}
