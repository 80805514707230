.hero {
	&-container {
		@media #{$mq-largeScreen} {
			padding: 20px;
			max-width: 1350px;
			margin: auto;
		}
	}
	&-media {
		display: block;
		width: 100%;
	}
	&-background {
		&-video {
			.hero-image {
				@media #{$mq-largeScreen} {
					display: none;
				}
			}
			.hero-video {
				display: none;
				@media #{$mq-largeScreen} {
					display: block;
				}
			}
		}
	}
	&-content {
		position: relative;
		width: calc(100% - 2em);
		background: #fff;
		padding: 2em 1em;
		margin: -1em auto auto;
		box-shadow: 0px -0.25em 0.25em rgba(#000, 0.07);
		@media #{$mq-largeScreen} {
			width: 80%;
			padding: 3em;
			margin: -5em auto auto;
		}
	}
}
