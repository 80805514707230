.testimonial {
	&-container {
		@extend %container;
	}
	&-wrap {
		@extend .bordered;
		@extend .bordered-top-left !optional;
		@extend .bordered-teal !optional;
	}
	&-content {
		$padding: 2em;
		$border-padding: $frame-padding-mobile + $padding;
		background: $color-blue;
		padding: $padding $border-padding $border-padding $padding;
		color: $white;
		text-align: center;
		@media #{$mq-mediumScreen} {
			$padding: 4em;
			$border-padding: $frame-padding-desktop + $padding;
			background: $color-blue;
			padding: $padding $border-padding $border-padding $padding;
		}
	}
	&-quote {
		font-family: $font-2;
		margin-bottom: 0.5em;
		font-size: 1.625em;
		@media #{$mq-mediumScreen} {
			font-size: 2.125em;
		}
		p {
			color: inherit;
			line-height: 1.1;
		}
	}
	&-author {
		color: inherit;
		font-size: 1em;
		line-height: 1.2;
		text-transform: uppercase;
		letter-spacing: 0.3125em;
		@media #{$mq-mediumScreen} {
			font-size: 1.125em;
			line-height: 1.1;
			letter-spacing: 0.3375em;
		}
	}
}
