.links-grid {
	@extend %container;
	@media #{$mq-largeScreen} {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	&-item {
		display: block;
		margin-bottom: 0.75em;
		text-decoration: none;
		@media #{$mq-largeScreen} {
			@include flex-wrap-column(3, 0.75em, 0.75em);
		}
	}
	&-caption {
		color: #ffffff;
		background: #000000;
		padding: 1em;
		display: flex;
		justify-content: space-between;
		align-items: center;
		p {
			margin: 0;
			line-height: 1;
			font-size: 1.625em;
			font-family: $font-2;
			font-weight: normal;
			text-transform: uppercase;
			@media #{$mq-largeScreen} {
				font-size: 1.875em;
			}
		}
		svg {
			font-size: 1.875em;
			margin: 0;
		}
	}

	&-red {
		.links-grid-caption {
			background: $color-red;
		}
	}

	&-teal {
		.links-grid-caption {
			background: $color-teal;
		}
	}

	&-blue {
		.links-grid-caption {
			background: $color-blue;
		}
	}
}
