.locations {
	@extend %container;

	&-properties {
		text-align: center;
		display: none;
		&.show {
			display: block;
		}

		&-header {
			margin-bottom: 1em;
		}

		&-list {
			display: inline-block;
			text-align: left;
			list-style-type: none;
			columns: 1;
			column-gap: 1em;
			column-fill: balance;
			@media #{$mq-largeScreen} {
				column-gap: 4em;
			}
		}

		&-item {
			position: relative;
			margin-bottom: 0.5em;
			display: flex;
			break-inside: avoid;
			@media #{$mq-largeScreen} {
				display: block;
			}
			&:before {
				content: '';
				position: relative;
				top: 0.25em;
				width: 0.5em;
				height: 0.5em;
				margin-right: 0.5em;
				background: #000;
				@media #{$mq-largeScreen} {
					position: absolute;
					left: -1.5em;
				}
			}
			a {
				color: inherit;
				text-decoration: none;
				&:hover {
					h5 {
						text-decoration: underline;
					}
				}
			}
			h5, p {
				margin: 0;
				line-height: 1;
			}
			p {
				font-weight: normal;
			}
		}
		@media #{$mq-largeScreen} {
			@for $i from 2 through 5 {
				&-#{$i}-columns {
					.locations-properties-list {
						columns: $i;
					}
				}
			}
		}
	}

	@each $name, $color in $theme-colors {
		&-#{$name} {
			.locations {
				&-properties {
					&-item {
						&:before {
							background-color: $color;
						}
					}
				}
			}
		}
	}
}
