.footer {
	text-align: center;
	@media #{$mq-largeScreen} {
		text-align: left;
	}
	&-container {
		@extend %container;
		padding: 4em 20px;
	}
	&-divisions {
		a {
			@extend h3;
			display: block;
			color: $color-text;
			margin: 2em 0;
			text-decoration: none;
		}
		@media #{$mq-largeScreen} {
			margin-bottom: 1.5em;
			a {
				display: inline-block;
				margin: 0 2em 0 0;
			}
		}
	}
	&-links {
		a {
			text-decoration: none;
			display: inline-block;
			padding: 0 0.5em;
		}
		@media #{$mq-largeScreen} {
			a {
				padding: 0;
				margin: 0 1em 0 0;
			}
		}
	}
	&-bottom {
		@media #{$mq-largeScreen} {
			display: flex;
			justify-content: space-between;
		}
	}
	&-copyright {
		margin: 0.5em 0;
	}
	&-credit {
		a {
			display: block;
			color: inherit;
			font-weight: normal;
			text-decoration: underline;
		}
		@media #{$mq-largeScreen} {
			text-align: right;
			a {
				display: inline;
			}
		}
	}
}
