.statepicker {
	&-map {
		display: none;
		@media #{$mq-largeScreen} {
			display: block;
			width: 100%;
			svg {
				width: 100%;
				g {
					position: relative;
				}
				.active {
					cursor: pointer;
				}
			}
		}
	}

	&-select {
		max-width: 300px;
		margin: 0 auto 2em;
	}

	@each $name, $color in $theme-colors {
		&-#{$name} {
			.statepicker {
				&-map {
					svg {
						.active {
							path {
								fill: $color;
							}
						}
						.selected {
							path {
								fill: darken($color, 10%);
							}
						}
					}
				}
				&-select {
					color: $color;
				}
			}
		}
	}
}
