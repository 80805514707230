.header {
	position: relative;
	background: #fff;
	width: 100%;
	z-index: 30;
	top: 0;

	a {
		text-shadow: none;
		box-shadow: none;
	}

	&__container {
		@extend %container;
		padding: 0;
		display: flex;
		justify-content: space-between;
		background: #ffffff;
	}

	&__branding {
		width: 100%;
		padding: 0 20px;
		position: relative;
		margin: 0;
		z-index: 30;
		background: #ffffff;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		a {
			display: block;
			@media #{$mq-largeScreen} {
				padding: 1em 0;
			}
			img {
				max-width: 100%;
				width: 170px;
				display: block;
				margin: 0;
				transition: filter 200ms;
			}
		}
	}
	.navigation-bars {
		font-weight: 700;
	}

	&__nav {
		//display: none;
		position: absolute;
		width: 100%;
		top: 100%;
		left: 0;
		z-index: 10;
		transform: translateY(-100%);
		transition: 500ms;
	}

	&__control {
		position: relative;
		flex: 0 0 10%;
		align-self: stretch;
		z-index: 30;
		svg {
			&.svg-inline--fa {
				display: block;
				color: $color-text;
				margin: 0;
				font-size: 34px;
				width: 1em;
				height: 1em;
			}
			&.fa-times {
				display: none;
			}
		}
	}

	@at-root .edit-mode & {
		position: relative;
	}
	@media #{$mq-xLargeScreen} {

		&__container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			//padding: 0 20px;
		}

		&__branding {
			background-color: transparent;
			flex: 0 0 auto;
			width: auto;
			margin-right: 1rem;
			align-self: center;

			a {
				padding: 0;

				img {
					max-width: none;
					margin: 0;
					width: 230px;
					padding: 2rem 0;
				}
			}
		}
		&__nav {
			position: static;
			display: block;
			top: auto;
			flex: 1 1 auto;
			z-index: 20;
			padding-right: 20px;
			transform: none;
		}
		&__control {
			display: none;
		}
	}
}
.utility-nav {
	display: none;
	@media #{$mq-xLargeScreen} {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		text-align: right;
		margin-top: 0.5em;
		&-list {
			&.ribbon-offset {
				padding-right: calc(925px - 50vw);
				// Gulp-SCSS isn't compatible with latest clamp syntax and throws an error
				padding-right: #{'min(925px - 50vw, 250px)'};
			}
		}
		&-link {
			display: inline-block;
			margin-left: 1.25em;
			text-decoration: none;
		}
		&-ribbon {
			$ribbon-bottom-offset: 0.5em;
			$ribbon-left-offset: 1.25em;
			position: absolute;
			text-align: left;
			right: 0;
			top: 0.5em;
			&:before {
				content: '';
				position: absolute;
				bottom: -$ribbon-bottom-offset;
				right: calc(100% - #{$ribbon-left-offset});
				border: 1.5em solid lighten($color-red, 5%);
				border-right-width: 0.5em + $ribbon-left-offset;
				border-left-width: 0.75em;
				border-left-color: transparent;
			}
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 100%;
				border: 0 solid transparent;
				border-top: $ribbon-bottom-offset solid darken($color-red, 5%);
				border-left-width: $ribbon-left-offset;
				border-right-width: 0.75em;
				z-index: 2;
			}
			&-link {
				position: relative;
				display: block;
				text-decoration: none;
				color: $white;
				background: $color-red;
				padding: 0.5em;
				transition: padding 300ms;
				z-index: 3;
				&:hover, &:focus {
					color: $white;
					padding-right: 2.5em;
				}
			}
			&-top, &-bottom {
				display: block;
				font-weight: normal;
				text-transform: uppercase;
				line-height: 1;
			}
			&-top {
				font-family: $font-1;
				letter-spacing: 2px;
			}
			&-bottom {
				font-family: $font-2;
				font-size: 1.5em;
				letter-spacing: 1px;
			}
		}
	}
}

.nav {
	&__wrapper {
		display: flex;
		pointer-events: none;
	}

	&__list {
		list-style-type: none;
		margin: 0;
		z-index: 20;

		&--primary {
			flex: 1 1 auto;
			background: $color-blue;
			padding: 1em 0;
		}

		&--secondary {
			overflow: hidden;
		}
	}

	&__item {
		position: relative;
		list-style-type: none;
		display: block;
		align-self: stretch;

		&--primary {
			&:last-child {
				.nav__list--secondary {
					left: auto;
					right: 0;
				}
			}
		}

		&--secondary {
			&:last-child {
				padding-bottom: 1em;
			}
		}

		&.child-open {
			.nav__link--primary {
			}


			.nav__link__child-toggle {
				.fa-chevron-right {
					display: none;
				}

				.fa-chevron-down {
					display: block;
				}
			}
			.nav__list--secondary {
				position: relative;
			}
		}

		&:not(.child-open) {
			.nav__list--secondary {
				max-height: 0 !important;
			}
		}
	}

	&__link {
		position: relative;
		width: auto;
		text-decoration: none;
		font-family: $font-1;
		transition: background-color 300ms;
		letter-spacing: 0.1em;
		padding: 1em 2.5em;
		color: $white;
		line-height: 1;

		&--primary {
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-transform: uppercase;
			letter-spacing: 0.3375em;
		}

		&--secondary {
			display: block;
			color: #fff;
			min-width: 15em;
			padding: 1em 4em;
		}

		&__label {
			svg.svg-inline--fa {
				display: none;
			}
		}

		&__child-toggle {
			padding: 1rem;
			transition: background-color 300ms;
			display: flex;
			align-items: stretch;
			background: $color-teal;
			margin: 0;
			font-size: 0.625rem;
			svg.svg-inline--fa {
				font-size: inherit;
				color: $white;
				margin: 0;
				width: 1em;
				height: 1em;
			}
			.fa-chevron-right {
			}

			.fa-chevron-down {
				display: none;
			}
		}
	}

	&__social {
		align-self: center;
		background: rgba(#fff, 0.97);
		padding: 1em;
		@media #{$mq-mediumScreen} {
			padding: 0;
		}

		a {
			font-size: 1.25em;
			padding: 0.25em;
			text-decoration: none;
			color: $color-blue;

			&:hover {
				color: $color-red;
			}
		}
	}

	&__toggle {
		text-align: center;
		padding: 2rem 20px;
		color: #ffffff;
		display: block;
		text-decoration: none;
		font-family: $font-1;
		letter-spacing: 0.25em;
		text-transform: uppercase;
		font-weight: 400;
		background: $white;
	}

	&__reserve {
		display: none;
	}

	@media #{$mq-xLargeScreen} {
		&__wrapper {
			pointer-events: auto;
		}
		&__list {
			transform: none;
			position: relative;

			&--primary {
				text-align: right;
				background-color: transparent;
			}

			&--secondary {
				pointer-events: none;
				opacity: 0;
				transition: opacity 300ms;
				position: absolute;
				left: 0;
				min-width: 100%;
				column-gap: 0;
				column-fill: balance;
				padding: 1em;
				background: $color-blue;
			}

			&--2-columns {
				columns: 2;
			}

			&--3-columns {
				columns: 3;
			}
		}
		&__item {
			display: inline-block;
			&--primary {
				margin-left: 3em;
				&:after {
					content: '';
					bottom: 0;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 10px 10px 10px;
					border-color: transparent transparent $color-blue;
					position: absolute;
					margin: 0 auto;
					left: 0;
					right: 0;
					opacity: 0;
					transition: 300ms;
				}
			}
			&--secondary {
				break-inside: avoid;
				&:last-child {
					padding-bottom: 0;
				}
			}
			&.hasChild {
				&.child-open {
					.nav__link--primary {
					}

					.nav__list--secondary {
						//display: block;
						pointer-events: auto;
						opacity: 1;
						position: absolute;
					}
					.nav__link--secondary {
					}
					.nav__link__child-toggle {

						.fa-chevron-down {
							display: block;
						}

						.fa-chevron-right {
							display: none;
						}
					}
					&:hover {
						&:after {
							opacity: 1;
						}
					}
				}
			}


			&:not(.child-open) {
				.nav__list--secondary {
					max-height: none !important;
				}
			}
		}
		&__link {
			text-align: center;
			outline: none;
			color: $color-text;

			&__label {
				vertical-align: middle;
				svg.svg-inline--fa {
					font-size: 0.625em;
					display: inline-block;
					margin: 0;
				}
			}

			&__child-toggle {
				padding: 0;
				background: none;
				color: inherit;
				margin-left: 0.5em;
				svg.svg-inline--fa {
					color: inherit;
					&.fa-chevron-right {
						display: none;
					}
					&.fa-chevron-down {
						display: block;
					}
				}
			}

			&--primary {
				//padding: 30px 20px;
				padding: 1em 0;
				background-color: transparent;
				height: 100%;
				vertical-align: middle;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&--secondary {
				text-align: left;
				background-color: rgba($color-blue, 0.97);
				color: #ffffff;
				padding: 0.25em 1em;

				span {
					padding: 0.5em 0;
					display: inline-block;
					position: relative;
					border: 2px solid transparent;
				}
				&:hover {
					span {
						border-bottom-color: currentColor;
					}
				}

			}
		}
		&__social {
			flex: 0 0 9em;
			text-align: center;
			background-color: transparent;
		}
		&__reserve {
			padding: 0;
			position: absolute;
			top: 100%;
			right: 0;
			display: block;

			.button {
				padding-left: 0.75rem;
				padding-right: 0.75rem;
				&:hover, &:focus {
					background: $color-red;
					color: #ffffff;
					text-decoration: underline;
				}
			}
		}

	}
}

.header.nav-open {
	.nav__wrapper {
		pointer-events: auto;
	}

	.header__nav {
		transform: translateY(0);
	}

	.header__control {
		svg {
			&.fa-bars {
				display: none;
			}
			&.fa-times {
				display: block;
			}
		}
	}
}

.skip-nav {
	display: none;

	@media #{$mq-largeScreen} {
		display: block;
		text-transform: uppercase;
		font-weight: 700;
		background-color: $color-red;
		color: #fff;
		outline: none;
		letter-spacing: 2px;
		font-size: 0.9em;
		padding: 20px 13px 5px;
		position: absolute;
		top: -15px;
		left: 25%;
		transform: translateY(-100%);
		transition: opacity 200ms 1s, transform 200ms 1s;
		opacity: 0;
		z-index: /* Over */
			9000;

		&:link, &:visited, &:hover {
			color: #fff;
		}

		&:focus {
			text-decoration: underline;
			opacity: 1;
			transform: translateY(0);
			transition-delay: 0s;
		}
	}
}
