.copy-media {
	@extend %container;
	@media #{$mq-largeScreen} {
		display: flex;
		align-items: center;
	}
	&-media {
		flex: 0 0 40%;
		margin-bottom: 1em;
		@media #{$mq-largeScreen} {
			margin: 0;
		}
	}
	&-copy {
		@media #{$mq-largeScreen} {
			padding: 0 2.875em;
		}
		> :last-child {
			margin-bottom: 0;
		}
	}
	&.media-right {
		.copy-media-media {
			@media #{$mq-largeScreen} {
				order: 2;
			}
		}
	}
}
