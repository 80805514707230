.logo-banner {
	margin: 2em auto;
	&-container {
		@extend %container;
		height: 7.5em;
		padding: 1em 0;
		@media #{$mq-largeScreen} {
			padding: 1em 20px;
			display: flex;
			justify-content: space-between;
		}
	}
	&-image {
		height: 100%;
		margin: 0 0.5em;
		@media #{$mq-largeScreen} {
			margin: 0;
			@supports (object-fit: contain) {
				max-width: 12.5%;
			}
		}
		img {
			width: auto;
			height: 100%;
			@media #{$mq-largeScreen} {
				display: block;
				@supports (object-fit: contain) {
					width: 100%;
					object-fit: contain;
				}
			}
		}
	}
	&-teal {
		background: $color-teal;
	}
	&-red {
		background: $color-red;
	}
	&-blue {
		background: $color-blue;
	}
}
