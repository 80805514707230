.columns {
	@extend %container;
	@media #{$mq-largeScreen} {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	&-item {
		margin: 2em 0;
		@media #{$mq-largeScreen} {
			@include flex-wrap-column(1, 1em);
		}
	}
	&-content {
		margin-top: 1em;
		@media #{$mq-largeScreen} {
			padding: 1em;
			p {
				margin: 1em 0;
			}
		}
	}
	&-4 {
		.columns-item {
			@media #{$mq-largeScreen} {
				@include flex-wrap-column(4, 2em);
			}
		}
	}
	&-3 {
		.columns-item {
			@media #{$mq-largeScreen} {
				@include flex-wrap-column(3, 2em);
			}
		}
	}
	&-2 {
		.columns-item {
			@media #{$mq-largeScreen} {
				@include flex-wrap-column(2, 4em);
			}
		}
	}
}
