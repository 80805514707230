h1, h2 {
	color: $color-blue;
}

a {
	color: $color-teal;
	&:hover, &:focus {
		color: $color-blue;
	}
}
