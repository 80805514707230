.taleo {
	@extend %container;
	&-slider {
		overflow: visible;
		margin: 2em -1em;
		.slick-slide {
			margin: 0 1em;
		}
		.slick-list {
			overflow: visible;
		}
	}
	&-item {
		display: flex;
		font-family: $font-1;
		padding: 1em;
		box-shadow: 0 0 4px rgba(#000, 0.41);
		&-container {
			font-size: 0.75em;
			display: block;
			flex: 1 1 0px;
			color: #666;
			h4, p, a {
				font-size: inherit;
				font-family: inherit;
			}
			p {
				color: inherit
			}
		}
	}
	&-title, &-id {
		font-size: 1em;
		line-height: 1;
		margin: 0;
		display: inline-block;
	}
	&-title {
		font-size: 1.125em;
		margin-right: 1em;
		color: #000000;
	}
	&-details {
		p {
			font-size: 1em;
			line-height: 1;
			margin: 0;
		}
	}
	&-links {
		a {
			font-size: 0.875em;
			color: $color-text;
			font-weight: normal;
			&:hover {
				text-decoration: none;
			}
		}
	}
}
