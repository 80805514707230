.discounts {
	@extend %container;

	&-intro {
		text-align: center;
		margin-top: 2em;
		p {
			margin: 0;
		}
	}

	&-properties {
		margin-top: 2em;
		display: none;

		&.visible {
			display: block;
		}

		&-item {
			display: none;
			margin: 2em 0;

			@media #{$mq-largeScreen} {
				margin: 4em 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			.copy-media {
				padding: 0;
			}

			&.visible {
				display: block;
			}
		}

		&-selector {
			margin-top: 1em;
			@media #{$mq-largeScreen} {
				margin-top: 2em;
			}
		}
	}

	&-actions {
		@media #{$mq-largeScreen} {
			text-align: center;
		}
	}
}
