.tooltip {
	pointer-events: none;
	background: rgba(#fff, 0.975);
	z-index: 8000;
	position: fixed;
	transform: translateX(-50%) translateY(calc(-100% - 0.5em)) scale(0, 0);
	transform-origin: center bottom;
	transition: transform 300ms linear;
	border-radius: 0.25em;
	box-shadow: 0.125em 0.125em 0.25em rgba(#000, 0.125);
	&.visible {
		transition-timing-function: cubic-bezier(1, .04, .65, 1.32);
		transform: translateX(-50%) translateY(calc(-100% - 0.5em)) scale(1, 1);
	}
	&-content {
		color: $color-text;
		padding: 0.5em;
		h4 {
			margin-bottom: 0.5em;
		}
		p {
			margin: 0;
		}
	}
}
