// NOTE: A lot of the styles ere are dynamically generated and can configured in abstracts/_variables

.bordered {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		border: 0.1875em solid #000000;
		pointer-events: none;
	}
	img, video {
		position: relative;
		z-index: 2;
	}
	@each $v in $frame-positions-vertical {
		@each $h in $frame-positions-horizontal {
			$other-v: pair-get-other($frame-positions-vertical, $v);
			$other-h: pair-get-other($frame-positions-horizontal, $h);
			&-#{$v}-#{$h} {
				padding-#{$v}: $frame-padding-mobile;
				padding-#{$h}: $frame-padding-mobile;
				&:before {
					#{$other-v}: $frame-padding-mobile;
					#{$other-h}: $frame-padding-mobile;
				}
				@media #{$mq-mediumScreen} {
					padding-#{$v}: $frame-padding-desktop;
					padding-#{$h}: $frame-padding-desktop;
					&:before {
						#{$other-v}: $frame-padding-desktop;
						#{$other-h}: $frame-padding-desktop;
					}
				}
			}
		}
	}
	@each $name, $color in $theme-colors {
		&-#{$name} {
			&:before {
				border-color: $color;
			}
		}
	}
}

.image-frame {
	&-1 {
		@extend .bordered;
		@extend .bordered-teal !optional;
		@extend .bordered-bottom-left !optional;
	}
	&-2 {
		@extend .bordered;
		@extend .bordered-red !optional;
		@extend .bordered-top-left !optional;
	}
	&-3 {
		@extend .bordered;
		@extend .bordered-blue !optional;
		@extend .bordered-bottom-left !optional;
	}
}
